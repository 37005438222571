<template>
    <div class="home">
        <x-header back>{{page_title}}</x-header>
        <x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
            <bounce-loading></bounce-loading>
        </x-content>
        <x-content v-else  :contentStyle="{display:'block',background: 'white',marginTop:'15px',width:'calc(100% - 60px)',padding:'16px'}">
            <form-create :rule="preRule" :option="preOptions"></form-create>
             <el-form :label-width="tagLabelWidth">
               <el-form-item label="实验分类" v-if="projectDatas&&projectDatas.length>0">
                 <el-select  style="width:230px;" v-model="typeId">
                   <el-option :value="item.id" :label="item.name" v-for="(item,index) in projectDatas" :key="item.id"></el-option>
                 </el-select>
               </el-form-item>
              <el-form-item  label="自定义标签"  >
              <el-tag :key="tag" v-for="tag in keyWordTags" closable  size="medium"
                :disable-transitions="true" @close="handleClose(tag)">{{tag}}
              </el-tag>
              <el-input  class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
                @keypress.native.enter="handleInputConfirm"
                @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm" placeholder="请输入"></el-input>
              <el-button v-else class="btnInfo" size="mini" @click="showInput"><i class="el-icon-plus"></i> 添加</el-button>
            </el-form-item>
            <el-form-item   >
              <el-button type="primary" icon="el-icon-document-checked"  size="medium" @click="onSubmit(0)"> 保存</el-button>
              <el-button type="success" icon="el-icon-circle-check" style="margin-left:30px" size="medium" @click="onSubmit(1)" v-if="this.groupId!=UNSORTED_ID"> 保存并提交</el-button>
            </el-form-item>
            </el-form>


        </x-content>
        <app-dialog ref="addDialog" dialogTitle="签名" originTitle staticDialog width="600px">
          <el-form :model="userSignature" ref="userSignatureForm" >
          <div style="display: flex;justify-content: center;">
            <vue-esign
                ref="esign"
                class="mySign"
                :width="600"
                :height="300"
                :isCrop="false"
                :lineWidth="6"
                lineColor="#000000"
                bgColor.sync="bgColor"
            />
          </div>
             <el-form-item >
            <el-input v-model="userSignature.password" show-password @clear="userSignature.password = ''" placeholder="请输入签名密码，密码格式为6位数字，下次输入密码即可完成电子签名"></el-input>
            </el-form-item>
           </el-form>
        <div class="save_btn_warp">
          <el-button type="warning" style="width:20%;" size="medium" @click="resetSign">清空签名</el-button>
          <el-button type="primary" style="width:20%;" size="medium" @click="saveSignImg">确定</el-button>
        </div>
        </app-dialog>
        <app-dialog  ref="userSignatureDialog" dialogTitle="验证签名密码" originTitle staticDialog width="600px">
           <div>
          <el-form >
            <el-form-item  v-model="password">
              <el-input  v-model="password" show-password placeholder="请输入签名密码"></el-input>
            </el-form-item>
            <div class="save_btn_warp">
              <el-button type="primary" @click="verify" style="width:200px;" size="medium" :loading="saveLoading">确定</el-button>
            </div>
          </el-form>
          </div>
        </app-dialog>
    </div>
</template>
<script>
import {GetGroupIdByUserId, GetGroupById, GetAllGroupUser} from "@/service/user"
    import {Detail} from "@/service/formTemplate";
    import {Save,Update,GetDetail} from "@/service/experiment";
    import {GetSignature, SignatureSave, Verify} from "@/service/userSignature";
    import formCreate from '@form-create/element-ui'
import {projectTypeListData} from "@/service/projectType";
    export default {
        name:'ExperimentEdit',
        components:{
			formCreate: formCreate.$form()
		},
        data(){
            return {
                loading:false,
                saveLoading:false,
                groupId:"",
                formTemplateId:"",
                groupData:{},
                formTemplate:{},
                preRule:[],
				        preOptions:{},
                groupIdList:[],
                experimentObj:{},
                signImg:"",
                param:{},
                isCopy:"",
                group:{},
                password:"",
                userSignature:{},
                groupDataList:[],
                review_users:[],
                inputVisible: false,
                inputValue: '',
                tagLabelWidth:'125px',
                keyWordTags:[],
                projectDatas:[],
                typeId:'',
                parentId:'',
                submitType:-1
              }
        },
        created(){
            this.parentId=this.$route.params.parentId || '';
            this.groupId = this.$route.params.groupId || '';
            this.formTemplateId = this.$route.params.formTemplateId || '';
            this.id = this.$route.params.id
            this.isCopy = this.$route.params.isCopy
            if(this.groupId){
                this.detailGroup();
                this.listGroupUser();
                this.getAllData()
            }
            if(this.formTemplateId){
                this.detailFormTemplate();
            }
            // this.getGroupIdByUserId();
        },
        computed:{
            page_title: function (){
                if(this.id!="1" ){
                    if(this.isCopy=="0"){
                        return "修改实验";
                    }else{
                        return "复制新建实验";
                    }
                }
                return "新建实验";
            },
        },
        methods:{
          getAllData(){
            this.loading = true;
            projectTypeListData(this.groupId).then(e => {
              let data = this.APIParse(e);
              this.projectDatas = data;
            }).catch(e => {
              this.$message.error(e.message)
            }).finally(() => {
              this.loading = false
            })
          },
          listGroupUser(){
            this.addGroupUserMap = {};
            this.loading = true;
            GetAllGroupUser(this.groupId).then(e => {
              let data = this.APIParse(e);
              this.groupDataList = data;
              data.forEach(item => {
                if (item.role.includes('4')){
                  const reviewUser = {
                    id: item.userId,
                    name: item.userName
                  };
                  this.review_users.push(reviewUser);
                }
              })
            }).catch(e => {
              this.$message.error(e.message)
            }).finally(e => {
              this.loading = false;
            })
          },
            getGroupIdByUserId(){
                GetGroupIdByUserId().then(e => {
                    let data = this.APIParse(e);
                    this.groupIdList = data;
                })
            },
            detailGroup(){
                    GetGroupById(this.groupId).then(e => {
                        let data = this.APIParse(e);
                        this.groupData = data;
                    })  
                
            },
            detailFormTemplate(){
                Detail(this.formTemplateId).then(e => {
                    let data = this.APIParse(e);
                    this.formTemplate = data;
                    if(this.id != '1'){
                      GetDetail(this.id).then(e => {
                        let data = this.APIParse(e);
                        this.experimentObj = data?.[0]?._source;
                        if(this.experimentObj.parent_id){
                          this.parentId=this.experimentObj.parent_id
                        }
                        if(this.experimentObj.keyword_tags){
                          this.keyWordTags=this.experimentObj.keyword_tags;
                        }
                        if(this.experimentObj.type_id){
                          this.typeId=this.experimentObj.type_id;
                        }
                        this.preRule = formCreate.parseJson(this.formTemplate.rule);
                          this.preRule.forEach(item => {
                              if(this.FORM_EXPERIMENT_FIELD.indexOf(item.field)>=0){
                                  if(this.experimentObj[item.field]){
                                      item.value = this.experimentObj[item.field]
                                  }
                              }else{
                                  let template_data = JSON.parse(this.experimentObj['template_data'])
                                  if(template_data[item.field]){
                                      item.value = template_data[item.field]
                                  }
                              }
                          })
                      })
                    }else{
                     this.preRule = formCreate.parseJson(this.formTemplate.rule);
                    }
                    this.preOptions = JSON.parse(this.formTemplate.options);
                    this.tagLabelWidth=this.preOptions.form.labelWidth
                    this.preOptions.submitBtn = false

                })
            },
            resetSign(){
                this.$refs.esign.reset();
            },
            verify(){
               Verify(this.password).then(e => {
               let data = this.APIParse(e);
               if (data) {
                 this.$refs['userSignatureDialog'].hide()
                 this.password=''
                 this.saveExperimentEdit()
               }else{
                this.$message.error("签名密码输入错误，请检查")
                this.password='';
               }
              })
            },
          saveSignImg(){
            if(!this.userSignature.password){
              this.$message({
                type: "warning",
                message: "请输入签名密码",
              });
              return
            }
            var patten =/^\d{6}$/;
            if (!patten.test(this.userSignature.password)) {
              this.$message("请输入正确的密码格式");
              return false;
            }
            this.$refs.esign
                .generate() // 使用生成器调用把签字的图片转换成为base64图片格式
                .then((res) => {
                  // 构造 FormData，并将签名图片对象和签名密码作为字段传入
                  this.signImg = res;
                  // this.$refs['addDialog'].hide();
                  // this.param.sign_img = res;a
                  this.userSignature.signature=res
                  if(!this.userSignature.signature){
                    this.$message({
                      type: "warning",
                      message: "您还没有签名",
                    });
                    return
                  }
                  SignatureSave(this.userSignature).then(e => {
                    if(e.code ==-1){
                      this.$message.error(e.msg)
                      return;
                    }
                    let data = this.APIParse(e)
                    this.$refs['addDialog'].hide()
                    this.saveExperimentEdit()
                  })
                })
                .catch((err) => {
                  // 画布没有签字时会执行这里提示一下
                  this.$message({
                    type: "warning",
                    message: "您还没有签名",
                  });
                });
            },
            saveExperimentEdit(){
              this.param.submit_sign_img=this.userSignature.signature
              if(this.param.uuid){
                this.$message.info("实验上链中，请耐心等待")
                    this.loading = true;
                Update(this.param).then(e => {
                  this.loading = false;
                  if(e.code ==-1){
                    this.$message.error(e.msg)
                    return;
                  }
                  this.$message.success("操作成功")
                  //根据实验判断返回的路由
                  if(this.groupId){
                    if(this.UNSORTED_ID==this.groupId){
                      this.$router.push('/manager/experiment/'+this.UNSORTED_ID);
                    }else{
                      this.$router.push('/manager/experimentType/'+this.groupId);
                    }
                  }else{
                    this.$router.push('/manager/experimentType/1');
                  }
                })
              }else{
                if(this.submitType==1){
                  this.$message.info("实验上链中，请耐心等待")
                }
                    this.loading = true;
                Save(this.param).then(e => {
                  this.loading = false;
                  if(e.code ==-1){
                    this.$message.error(e.msg)
                    return;
                  }
                  this.$message.success("操作成功")
                  //根据实验判断返回的路由
                  if(this.groupId){
                    if(this.UNSORTED_ID==this.groupId){
                      this.$router.push('/manager/experimentType/'+this.UNSORTED_ID);
                    }else{
                      this.$router.push('/manager/experimentType/'+this.groupId);
                    }
                  }else{
                    this.$router.push('/manager/experiment/1');
                  }
                })
              }

            },
  
            onSubmit(submitType){
              this.submitType=submitType
                if(this.isCopy=="1"){
                    this.id = "1"
                }
              this.param = {};
              if (this.typeId){
               this.param.type_id= this.typeId
              };
              this.param.user = {"avatar":this.user.avatar,id:this.user.id,"name":this.user.name};
              let template_data = {};
              for(let key in this.preRule){
                let item_data=this.preRule[key]
                if(this.FORM_EXPERIMENT_FIELD.indexOf(item_data.field)>=0){
                  this.param[item_data.field] = item_data.value
                }else{
                  template_data[item_data.field] = item_data.value
                }
              }
              if(!this.param.subject||!this.param.subject.trim()){
                this.$message.warning("请输入实验名称")
                return
              }
              if(template_data.date&&Date.parse(template_data.date) > new Date()){
                 this.$message.warning("实验时间不能大于当前时间")
                return
              }
              this.param.template_data = JSON.stringify(template_data)
             
                /**this.param.note = {
                    "id":this.noteId,
                   */
                // this.param.group_id= this.groupIdList
                this.param.group_id = [this.groupId]
                this.param.form_template_id = this.formTemplateId
                this.param.parent_id = this.parentId
                this.param.keyword_tags=this.keyWordTags
               // if(this.groupData && this.groupData.reviewUserId){
                //     this.param.review_user = {"id":this.groupData.reviewUserId,"name":this.groupData.reviewUserName}
                // }
              if(this.review_users){
                this.param.review_user = this.review_users
              }
              //如果是仅保存，状态设置待提交
              if(this.id!="1"){
                this.param.uuid = this.id;
              }
              if(submitType==0){
                this.param.status='3'
                  if(this.id!="1"){
                    this.param.uuid = this.id;
                    if(this.experimentObj.status=='2'){
                        this.param.status='3'
                    }
                    this.loading = true;
                    Update(this.param).then(e => {
                      this.loading=false
                        if(e.code ==-1){
                            this.$message.error(e.msg)
                            return;
                        }
                        this.$message.success("操作成功")
                        //根据实验判断返回的路由
                        if(this.groupId){
                          if(this.UNSORTED_ID==this.groupId){
                            this.$router.push('/manager/experimentType/'+this.UNSORTED_ID);
                          }else{
                            this.$router.push('/manager/experimentType/'+this.groupId);
                          }
                        }else{
                          this.$router.push('/manager/experimentType/1');
                        }
 
                    })
                }else{
                  GetSignature().then(e => {
                        if (e.data) {
                          this.userSignature = e.data;
                          this.$refs['userSignatureDialog'].show()
                        } else {
                          this.$refs['addDialog'].show()
                        }
                      }
                  ).catch(e => {
                    this.$message.error(e.message)
                  }).finally(e => {
                  })
                }
              }else{
                this.param.status='0'
                GetSignature().then(e => {
                        if (e.data) {
                          this.userSignature = e.data;
                          this.$refs['userSignatureDialog'].show()
                        } else {
                          this.$refs['addDialog'].show()
                        }
                      }
                  ).catch(e => {
                    this.$message.error(e.message)
                  }).finally(e => {
                  })
              }

			      },
            setPreRuleValue(){
               
            },
            handleClose(tag) {
              this.keyWordTags.splice(this.keyWordTags.indexOf(tag), 1);
            },
            handleInputConfirm() {
              let inputValue = this.inputValue;
              if (inputValue) {
                this.keyWordTags.push(inputValue);
              }
              this.inputVisible = false;
              this.inputValue = '';
            },
            showInput() {
              this.inputVisible = true;
              this.$nextTick(() => {
                this.$refs.saveTagInput.$refs.input.focus();
              });
            },
        }
    }
</script>
<style lang="scss" scoped>
    .group_operate+.group_operate{
        margin-left: 15px;
    }
    .Width-100{width: 100%}
    .home{width: 100%;height: 100%;overflow: auto;}
    .search_warp{width:100%;margin: 16px 0px;}
    
    .save_btn_warp{width: 100%;margin-top: 32px;display: flex;align-items: center;justify-content: center;}
    .page-warp{
        text-align: center;
        margin-top: 20px;
    }
    .mySign {
        border: 1px dashed #000;
    }
    .base-tag{
      margin-right: 10px;
    }
    .input-new-tag{
      width: 120px;
    }
</style>